import { useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  Grid,
  Box,
  Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';

export default function MatchDrawWinners({ matchId, callAPI }) {
  const [winnersData, setWinners] = useState();
  const [pages, setPages] = useState(0);
  const [winnerCount, setWinnerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [input, setInput] = useState(1);
  const [options, setOptions] = useState({ size, page: 0, matchId });

  const getWinners = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/draw/admin/all/match`, {
        params: options,
      })
      .then((res) => {
        console.log('winners', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setWinnerCount(res.data.data.maxRecords);
          setWinners(res.data.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getWinners(temp);
  };

  useEffect(() => {
    getWinners(options);
  }, []);
  useEffect(() => {
    getWinners(options);
  }, [callAPI]);
  return (
    <Page title="User">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Typography variant="h4">Winners</Typography>
          <Card>
            <Box padding={1}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table size="small">
                    {/* <MatchListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Position</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Mobile Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {winnersData?.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell>
                            <Stack direction="row" alignItems={'end'}>
                              <Iconify sx={{ marginRight: '5px', fontSize: '28px' }} icon="emojione:trophy" />
                              <Typography sx={{ fontWeight: 'bold', padding: 0, marginBottom: '-5px' }}>
                                {item.position}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell sx={{ maxWidth: '100px' }}>
                            <Stack direction="row" alignItems={'center'}>
                              <Avatar
                                sx={{ marginRight: '5px' }}
                                alt="Remy Sharp"
                                src="https://www.w3schools.com/howto/img_avatar.png"
                              />
                              <Typography sx={{ fontWeight: 'bold', padding: 0, marginBottom: '-5px' }}>
                                {item.userDetails?.fullname?.toUpperCase()}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell>
                            <Typography sx={{ fontWeight: 'bold', padding: 0, marginBottom: '-5px' }}>
                              {item.userDetails?.mobileNumber}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[]}
                component={'div'}
                count={winnerCount}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Box>
          </Card>
        </Container>
      )}
    </Page>
  );
}
