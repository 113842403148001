import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Countries from './pages/Countries';
import Matches from './pages/Matches';
import ViewMatch from './pages/ViewMatch';
import Polls from './pages/Polls';
import Winners from './pages/Winners';
import LeaserBoard from './pages/LeaderBoard';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'countries', element: <Countries /> },
        { path: 'matches', element: <Matches /> },
        { path: 'match/:id', element: <ViewMatch /> },
        { path: 'polls', element: <Polls /> },
        { path: 'winners', element: <Winners /> },
        { path: 'leaderboard', element: <LeaserBoard /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'login', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

