import { Button, Container, Grid, Stack, TablePagination, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import AddCountry from '../components/AddCountry';
import CountryCard from '../components/CountryCard';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

import PRODUCTS from '../_mock/products';

export default function Countries() {
  const [countries, setCountries] = useState([]);
  const [countriesCount, setCountriesCount] = useState(0);
  const [pages, setPages] = useState(0);
  const size = 12;
  const [options, setOptions] = useState({ size, page: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const getCountries = async (options) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/country/admin/all`, { params: options });
      setCountries(res.data.data.records);
      setCountriesCount(res.data.data.maxRecords);
      console.log('res', res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    getCountries(temp);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeDialogSuccess = () => {
    setOpen(false);
    getCountries(options);
  };

  useEffect(() => {
    getCountries(options);
  }, []);
  return (
    <Page title="Countries">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <AddCountry open={open} setOpen={setOpen} closeDialogSuccess={closeDialogSuccess} />
          <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Countries</Typography>
            <Button
              variant="contained"
              size="small"
              onClick={handleClickOpen}
              startIcon={<Iconify icon="carbon:add-alt" />}
            >
              ADD
            </Button>
          </Stack>
          <Grid container spacing={3}>
            {countries.map((item, key) => (
              <Grid item key={key} xs={12} sm={6} md={2}>
                <CountryCard slNo={(key + 1) * (pages + 1)} country={item} />
              </Grid>
            ))}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[]}
            component={'div'}
            count={countriesCount}
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </Container>
      )}
    </Page>
  );
}
