import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Filter from '../components/MatchesFilters';

export default function Match() {
  const [matchData, setMatchData] = useState();
  const [pages, setPages] = useState(0);
  const [matchCount, setMatchCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [input, setInput] = useState(1);
  const [firstTeam, setFirstTeam] = useState([]);
  const [secondTeam, setSecondTeam] = useState([]);
  const loading = open1 && firstTeam.length === 0;
  const loading1 = open2 && firstTeam.length === 0;
  const [buttonDisable, setButtonDisable] = useState(false);
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState('');
  const [matchId, setMatchId] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState({});

  const matchSchema = Yup.object().shape({
    firstTeam: Yup.string().required('First team is required'),
    secondTeam: Yup.string().required('Second team is required'),
    matchDate: Yup.string().required('Match date is required').nullable(),
    matchType: Yup.string().required('Match Type is required').nullable(),
  });

  const formik = useFormik({
    initialValues: {
      firstTeam: '',
      secondTeam: '',
      matchDate: '',
      matchType: '',
    },
    validationSchema: matchSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/match/admin`, values);
        if (!res.data.isError) {
          closeAddMatchModal(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getMatchs(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const openAddMatchModal = () => {
    setOpen(true);
  };
  const closeAddMatchModal = (message) => {
    setOpen(false);
    toast(message);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getMatchs(temp);
  };

  const getMatchs = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/match/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMatchCount(res.data.data.maxRecords);
          setMatchData(res.data.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/poll/admin/status`, {
        matchId,
        status,
      })
      .then((res) => {
        handleClose();
        getMatchs(options);
        setMatchId('');
        toast.success(res.data.message);
      })
      .catch((err) => {
        handleClose();
        setMatchId('');
        toast.error(err.response.data.message);
      });
  };

  // const handleStatusChange = (event, item, index) => {
  //   setPopup(true);
  //   setStatus(event.target.value);
  //   setMatchId(item._id);
  // };

  const handleClose = () => {
    setPopup(false);
    // setDeletePopup(false);
  };

  const goToPage = () => {
    if (input > Math.ceil(matchCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getMatchs(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.title.length) {
      temp.title = filter.title;
    }
    if (filter.matchDate.length) {
      temp.matchDate = filter.matchDate;
    }
    if (filter.pollingStatus.length) {
      temp.pollingStatus = filter.pollingStatus;
    }
    setOptions(temp);
    setPages(0);
    getMatchs(temp);
  };

  useEffect(() => {
    getMatchs(options);
  }, []);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    const getCountries = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/country/admin/all/list`);
        setFirstTeam(res.data.data.records);
        console.log('res', res);
      } catch (error) {
        console.log(error);
      }
    };
    getCountries();
  }, [loading]);

  useEffect(() => {
    if (!open1) {
      setFirstTeam([]);
    }
  }, [open1]);

  useEffect(() => {
    if (!loading1) {
      return undefined;
    }
    const getCountries = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/country/admin/all/list`);
        setSecondTeam(res.data.data.records);
        console.log('res', res);
      } catch (error) {
        console.log(error);
      }
    };
    getCountries();
  }, [loading1]);

  useEffect(() => {
    if (!open2) {
      setSecondTeam([]);
    }
  }, [open2]);

  useEffect(() => {
    console.log(values);
    if (values.firstTeam.length && values.secondTeam.length) {
      if (values.firstTeam === values.secondTeam) {
        toast.error('Select different teams');
        setButtonDisable(true);
      } else {
        setButtonDisable(false);
      }
    }
  }, [values]);
  return (
    <>
      <Page title="Matches">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add match Dialog */}
            <Dialog open={open}>
              <DialogTitle>Add Match</DialogTitle>
              <Grid container paddingBottom={'10px'} Width="500px">
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'column'} spacing={1} width="500px">
                          <Stack direction="row" justifyContent="space-between" spacing={1}>
                            <Autocomplete
                              id="asynchronous-demo"
                              fullWidth
                              open={open1}
                              onOpen={() => {
                                setOpen1(true);
                              }}
                              onClose={() => {
                                setOpen1(false);
                              }}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => option.name}
                              options={firstTeam}
                              loading={loading}
                              onChange={(_, value) => formik.setFieldValue('firstTeam', value._id)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(touched.firstTeam && errors.firstTeam)}
                                  helperText={touched.firstTeam && errors.firstTeam}
                                  label="First Team"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              id="asynchronous-demo"
                              fullWidth
                              open={open2}
                              onOpen={() => {
                                setOpen2(true);
                              }}
                              onClose={() => {
                                setOpen2(false);
                              }}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => option.name}
                              options={secondTeam}
                              loading={loading1}
                              onChange={(_, value) => formik.setFieldValue('secondTeam', value._id)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(touched.secondTeam && errors.secondTeam)}
                                  helperText={touched.secondTeam && errors.secondTeam}
                                  label="Second Team"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
                            <Stack flex={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  renderInput={(props) => (
                                    <TextField
                                      error={Boolean(touched.matchDate && errors.matchDate)}
                                      helperText={touched.matchDate && errors.matchDate}
                                      {...props}
                                    />
                                  )}
                                  label="Match Time"
                                  value={values.matchDate}
                                  {...getFieldProps('matchDate')}
                                  onChange={(newValue) => {
                                    formik.setFieldValue('matchDate', newValue);
                                  }}
                                />{' '}
                              </LocalizationProvider>
                            </Stack>
                            <Stack flex={1}>
                              <TextField
                                select
                                fullWidth
                                label="Match Type"
                                {...getFieldProps('matchType')}
                                error={Boolean(touched.matchType && errors.matchType)}
                                helperText={touched.matchType && errors.matchType}
                              >
                                <MenuItem value="GROUP">GROUP</MenuItem>
                                <MenuItem value="PRE_QUARTER">PRE QUARTER</MenuItem>
                                <MenuItem value="QUARTER">QUARTER</MenuItem>
                                <MenuItem value="SEMI_FINAL">SEMI FINAL</MenuItem>
                                <MenuItem value="THIRD_PLACE">THIRD PLACE</MenuItem>
                                <MenuItem value="FINAL">FINAL</MenuItem>
                              </TextField>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddMatchModal}>Cancel</Button>
                        <LoadingButton type="submit" disabled={buttonDisable} loading={isSubmitting}>
                          Add Match
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* Add match dialog end */}

            {/* polling status change dialog */}
            {/* <>
              <Dialog onClose={handleClose} open={popup}>
                <DialogTitle>Status Change</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you want to change polling status ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={updateStatus}>Yes</Button>
                </DialogActions>
              </Dialog>
            </> */}
            {/* polling status change dialog end */}

            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Matches
                </Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="carbon:add-alt" />}
                      onClick={openAddMatchModal}
                    >
                      MATCH
                    </Button>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      {/* <MatchListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No.</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Time</TableCell>
                          <TableCell>Match Type</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Winner</TableCell>
                          <TableCell>Team 1 Goals</TableCell>
                          <TableCell>Team 2 Goals</TableCell>
                          <TableCell>Polling Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matchData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell style={{ width: '50px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell sx={{ minWidth: '110px' }}>
                              {Moment(item.matchDate).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell> {Moment(item.matchDate).format('h:mm a')}</TableCell>
                            <TableCell>{item.matchType?.replaceAll("_"," ")}</TableCell>
                            <TableCell>{item.title}</TableCell>
                            {!item.isDraw && <TableCell>{item.winnerTeam?.name}</TableCell>}
                            {item.isDraw && <TableCell>DRAW</TableCell>}
                            <TableCell>{item.firstTeamGoals}</TableCell>
                            <TableCell>{item.secondTeamGoals}</TableCell>
                            <TableCell>{item.pollingStatus}</TableCell>
                            <TableCell>
                              <Stack direction="row" justifyContent="space-between" spacing={2}>
                                <Link to={`/dashboard/match/${item._id}`}>
                                  <Iconify sx={{ fontSize: '24px', color: 'gray' }} icon="ion:eye" />
                                </Link>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={matchCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
