import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components
import Label from './Label';
import ColorPreview from './color-utils/ColorPreview';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CountryCard.propTypes = {
  country: PropTypes.object,
};

export default function CountryCard({ country, slNo }) {
  const { name, countryFlag, countryCode } = country;

  return (
    <Card sx={{ height: '100%' }}>
      <Box sx={{ pt: '75%', position: 'relative' }}>
        <Label
          variant="filled"
          color={'info'}
          sx={{
            zIndex: 9,
            bottom: 2,
            right: 2,
            position: 'absolute',
            textTransform: 'uppercase',
          }}
        >
          {countryCode}
        </Label>
        <StyledProductImg alt={name} src={countryFlag} />
      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {`${slNo}.${name}`}
          </Typography>
        </Link>
      </Stack>
    </Card>
  );
}
