import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  Grid,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Polls from './Polls';
import Draw from '../components/Draw';
import MatchDrawWinners from '../components/MatchDrawWinners';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export default function ViewMatch() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [popup, setPopUp] = useState(false);
  const [status, setStatus] = useState('');
  const [matchData, setMatchData] = useState({});
  const [winnerModal, setWinnerModal] = useState(false);
  const [drawModal, setDrawModal] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [winnerCount, setWinnerCount] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);

  const getMatch = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/match/admin`, { params: { id } })
      .then((res) => {
        console.log('matchh', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMatchData(res.data.data.match);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMatch = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/match/admin/delete`, {
        id,
      })
      .then((res) => {
        handleClose();
        navigate(-1);
        toast.success(res.data.message);
      })
      .catch((err) => {
        handleClose();
        toast.error(err.response.data.message);
      });
  };

  const winnerSchema = Yup.object().shape({
    // winnerId: Yup.string().optional(),
    firstTeamGoals: Yup.string().required('First team goals is required').nullable(),
    secondTeamGoals: Yup.string().required('Secon team goals is required').nullable(),
  });

  const formik = useFormik({
    initialValues: {
      winnerId: '',
      matchId: id,
      firstTeamGoals: '',
      secondTeamGoals: '',
    },
    validationSchema: winnerSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/match/admin/winner`, { ...values, matchId: id });
        if (!res.data.isError) {
          closeAddwinnerModal();
        } else {
          toast.error(res.data.message);
        }
        getMatch();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/poll/admin/status`, {
        matchId: id,
        status,
      })
      .then((res) => {
        handleClose();
        getMatch();
        toast.success(res.data.message);
      })
      .catch((err) => {
        handleClose();
        toast.error(err.response.data.message);
      });
  };

  const handleClose = () => {
    setPopUp(false);
    setDeletePopup(false);
  };

  const handleStatusChange = (event) => {
    setPopUp(true);
    setStatus(event.target.value);
  };

  const openAddwinnerModal = () => {
    setWinnerModal(true);
  };
  const closeAddwinnerModal = (message) => {
    setWinnerModal(false);
    toast(message);
  };

  const handleDelete = () => {
    setDeletePopup(true);
  };
  const openAddDrawModal = () => {
    setDrawModal(true);
  };
  const closeDrawModal = (message) => {
    setDrawModal(false);
    toast(message);
  };
  const closeDrawModalSuccess = () => {
    setDrawModal(false);
    setCallAPI(!callAPI);
  };

  useEffect(() => {
    getMatch();
  }, []);

  return (
    <Page title="Match">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          {/* polling status change dialog */}
          <>
            <Dialog onClose={handleClose} open={popup}>
              <DialogTitle>Status Change</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to change polling status ?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={updateStatus}>Yes</Button>
              </DialogActions>
            </Dialog>
          </>
          {/* polling status change dialog end */}

          {/* Add winner Dialog */}
          <Dialog open={winnerModal}>
            <DialogTitle>Add Match Result</DialogTitle>
            <DialogContent paddingTop="20px">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2} mt={0.5} sx={{ minWidth: '300px' }}>
                    <Grid item xs={12}>
                      {/* <TextField
                        InputLabelProps={{ shrink: true }}
                        select
                        label="Winner"
                        fullWidth
                        {...getFieldProps('winnerId')}
                        error={Boolean(touched.winnerId && errors.winnerId)}
                        helperText={touched.winnerId && errors.winnerId}
                      >
                        <MenuItem value={matchData.firstTeam._id}>{matchData.firstTeam.name}</MenuItem>
                        <MenuItem value={matchData.secondTeam._id}>{matchData.secondTeam.name}</MenuItem>
                      </TextField> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label={`${matchData.firstTeam.name} goals`}
                        fullWidth
                        {...getFieldProps('firstTeamGoals')}
                        error={Boolean(touched.firstTeamGoals && errors.firstTeamGoals)}
                        helperText={touched.firstTeamGoals && errors.firstTeamGoals}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label={`${matchData.secondTeam.name} goals`}
                        fullWidth
                        {...getFieldProps('secondTeamGoals')}
                        error={Boolean(touched.secondTeamGoals && errors.secondTeamGoals)}
                        helperText={touched.secondTeamGoals && errors.secondTeamGoals}
                      />
                    </Grid>
                  </Grid>
                  <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                    <Button onClick={closeAddwinnerModal}>Cancel</Button>
                    <LoadingButton type="submit" loading={isSubmitting}>
                      Add Result
                    </LoadingButton>
                  </Stack>
                </Form>
              </FormikProvider>
            </DialogContent>
          </Dialog>
          {/* Add winner dialog end */}

          {/* draw modal */}
          {drawModal && (
            <Draw
              closeDrawModal={closeDrawModal}
              winnerCount={winnerCount}
              closeDrawModalSuccess={closeDrawModalSuccess}
            />
          )}

          {/* Delete match dialog */}
          <>
            <Dialog onClose={handleClose} open={deletePopup}>
              <DialogTitle>Delete Match</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to delete this match ?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={deleteMatch}>Yes</Button>
              </DialogActions>
            </Dialog>
          </>
          {/* Delete match dialog end */}

          <Card sx={{ padding: '5px 10px 10px 10px' }}>
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems="flex-start"
              mb={3}
              sx={{ width: '100%' }}
            >
              <Tooltip title="Go Back">
                <IconButton>
                  <Iconify
                    style={{ fontSize: '45px', cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                    icon="ion:arrow-back-sharp"
                  />
                </IconButton>
              </Tooltip>

              <Stack direction={'column'} sx={{ width: '100%' }} paddingRight={1}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4">{matchData.title}</Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  {Moment(matchData.matchDate).format('MMMM DD, YYYY')} &nbsp;
                  {Moment(matchData.matchDate).format('h:mm a')}
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6">{matchData.matchType?.replaceAll("_"," ")}</Typography>
                </Box>
              </Stack>
              <Tooltip title="Delete">
                <IconButton>
                  <Iconify
                    sx={{ fontSize: '26px', color: 'red', cursor: 'pointer' }}
                    icon="fluent:delete-32-filled"
                    onClick={handleDelete}
                  />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" alignItems={'end'} justifyContent="center" spacing={1} marginRight={1}>
              {matchData.winnerTeam && (
                <>
                  <Typography color="green" variant="h5">{`${matchData.winnerTeam.name}`}</Typography>
                  <Typography variant="h6">WON THE MATCH</Typography>
                </>
              )}
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={1} marginRight={1}>
              {matchData.isDraw && (
                <>
                  <Typography variant="h6">MATCH</Typography>
                  <Typography color="green" variant="h5">
                    DRAWN
                  </Typography>
                </>
              )}
            </Stack>

            <Grid container paddingBottom={1}>
              <Grid item xs={12}>
                <Stack
                  direction={{ lg: 'row', xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction={{ lg: 'row', xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent="center"
                    sx={{ width: '100%' }}
                    alignItems={'center'}
                  >
                    <Stack
                      sx={{ width: '250px' }}
                      direction={'column'}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Card sx={{ height: '120px', width: '150px' }}>
                        <Box sx={{ position: 'relative', height: '120px', width: '150px' }}>
                          <StyledProductImg alt={matchData?.firstTeam?.name} src={matchData?.firstTeam?.countryFlag} />
                        </Box>
                      </Card>
                      <Typography variant="h6">{matchData?.firstTeam?.name}</Typography>
                    </Stack>
                    {(matchData.winnerTeam || matchData.isDraw) && (
                      <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={1}>
                        <Iconify sx={{ fontSize: '34px' }} icon="ion:football-sharp" />
                        <Typography variant="h3">{matchData?.firstTeamGoals}</Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack direction={'column'} justifyContent="center" alignItems="center">
                    {(matchData.pollingStatus === 'STARTED' || matchData.pollingStatus === 'PENDING') && (
                      <Box>
                        <Typography variant="h3">Vs</Typography>
                      </Box>
                    )}
                    {!matchData.winnerTeam && matchData.pollingStatus === 'COMPLETED' && !matchData.isDraw && (
                      <Button startIcon={<Iconify icon="carbon:add-filled" />} onClick={openAddwinnerModal}>
                        RESULT
                      </Button>
                    )}
                    {(matchData.winnerTeam || matchData.isDraw) && (
                      <Button
                        variant="contained"
                        sx={{ bgcolor: '#fff', color: '#212B36', ':hover': { bgcolor: 'green', color: 'white' } }}
                        startIcon={<Iconify icon="emojione:trophy" />}
                        onClick={openAddDrawModal}
                      >
                        DRAW
                      </Button>
                    )}
                  </Stack>
                  <Stack
                    direction={{ lg: 'row', xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent="center"
                    sx={{ width: '100%' }}
                    alignItems={'center'}
                  >
                    {(matchData.winnerTeam || matchData.isDraw) && (
                      <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={1}>
                        <Iconify sx={{ fontSize: '34px' }} icon="ion:football-sharp" />
                        <Typography variant="h3">{matchData?.secondTeamGoals}</Typography>
                      </Stack>
                    )}
                    <Stack
                      sx={{ width: '250px' }}
                      direction={'column'}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Card sx={{ height: '120px', width: '150px' }}>
                        <Box sx={{ position: 'relative', height: '120px', width: '150px' }}>
                          <StyledProductImg
                            alt={matchData?.secondTeam?.name}
                            src={matchData?.secondTeam?.countryFlag}
                          />
                        </Box>
                      </Card>
                      <Typography variant="h6">{matchData?.secondTeam?.name}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} marginBottom={2}>
              <Typography>Polling Status :</Typography>
              <Typography marginLeft={2} sx={{ fontWeight: 'bold' }}>
                {matchData.pollingStatus}
              </Typography>
            </Stack>
            {(matchData.winnerTeam || matchData.isDraw) && <MatchDrawWinners callAPI={callAPI} matchId={id} />}
            {(matchData.pollingStatus === 'STARTED' || matchData.pollingStatus === 'COMPLETED') && (
              <Polls matchId={id} />
            )}
          </Card>
        </Container>
      )}
    </Page>
  );
}
