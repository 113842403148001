import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

import { FilePond, File, registerPlugin } from 'react-filepond';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Iconify from './Iconify';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function AddCountry({ open, setOpen, closeDialogSuccess }) {
  const [files, setFiles] = useState([]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setFiles([]);
  };

  const countrySchema = Yup.object().shape({
    name: Yup.string().required('Country code is required!'),
    countryCode: Yup.string().required('Required!').min(3, 'Must be of 3 Charecters').max(3, 'Must be of 3 Charecters'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      countryCode: '',
    },
    validationSchema: countrySchema,
    onSubmit: async () => {
      try {
        const data = new FormData();
        data.append('name', values.name);
        data.append('countryCode', values.countryCode);
        data.append('description', values.description);
        data.append('readingTime', values.readingTime);
        data.append('countryFlag', files[0].file);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/country/admin`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        formik.resetForm();
        setFiles([]);
        closeDialogSuccess();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction={'row'} justifyContent="space-between" alignItems="center">
            {'ADD COUNTRY'}
            <Iconify style={{ cursor: 'pointer' }} icon="bytesize:close" onClick={handleClose} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container mt={1} spacing={2}>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextField
                    type="text"
                    label="Country Name"
                    fullWidth
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item xs={6} lg={4} md={4} sm={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Country Code"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('countryCode')}
                    error={Boolean(touched.countryCode && errors.countryCode)}
                    helperText={touched.countryCode && errors.countryCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple
                    maxFiles={1}
                    credits={false}
                    // server="/api"
                    name="files"
                    labelIdle="Drag & Drop Flag Image"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                  <LoadingButton variant="contained" disabled={!files.length} type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
