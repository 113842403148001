import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  Grid,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Filter from '../components/LeaderBoardFilter';

export default function LeaserBoard() {
  const [leaderBoardData, setPollData] = useState();
  const [pages, setPages] = useState(0);
  const [leaderboardCount, setPollCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [input, setInput] = useState(1);
  const [options, setOptions] = useState({ size, page: 0 , matchType:["GROUP", "PRE_QUARTER", "QUARTER", "SEMI_FINAL", "FINAL", "THIRD_PLACE"]});

  const getLeaderboard = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/poll/admin/leadershipBoard/all`, {
        params: options,
      })
      .then((res) => {
        console.log('leaderboard', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setPollCount(res.data.data.maxRecords);
          setPollData(res.data.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    if (input > Math.ceil(leaderboardCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getLeaderboard(temp);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLeaderboard(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.mobileNumber.length) {
      temp.mobileNumber = filter.mobileNumber;
    }
    if (filter.matchType.length) {
      temp.matchType = filter.matchType;
    }

    setOptions(temp);
    setPages(0);
    getLeaderboard(temp);
  };

  useEffect(() => {
    getLeaderboard(options);
  }, []);
  return (
    <Page title="Polls">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Typography variant="h4">Leadership Board</Typography>
          <Card>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                  Go
                </Button>
              </Stack>
              <Filter matchType={options.matchType} applyFilters={applyFilters} />
            </Stack>
            <Stack justifyContent="center" alignItems="center" sx={{width:"100%"}}>
              <TableContainer sx={{ maxWidth: 720 }}>
                <Table size="small">
                  {/* <MatchListHead /> */}
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Fullname</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell align="center">Total Points</TableCell>
                      <TableCell align="center">Rank</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaderBoardData?.map((item, id) => (
                      <TableRow key={id}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>
                        <TableCell>{item.fullname?.toUpperCase()}</TableCell>
                        <TableCell sx={{width:"150px"}}>{item.mobileNumber}</TableCell>
                        <TableCell align='center' sx={{width:"130px"}}>{item.totalPoints}</TableCell>
                        <TableCell align='center' sx={{width:"130px"}}>{item.rank}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <TablePagination
              rowsPerPageOptions={[]}
              component={'div'}
              count={leaderboardCount}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
