// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'countries',
    path: '/dashboard/countries',
    icon: getIcon('fa6-solid:flag'),
  },
  {
    title: 'matches',
    path: '/dashboard/matches',
    icon: getIcon('icon-park-outline:football'),
  },
  {
    title: 'polls',
    path: '/dashboard/polls',
    icon: getIcon('material-symbols:how-to-vote-sharp'),
  },
  {
    title: 'winners',
    path: '/dashboard/winners',
    icon: getIcon('carbon:trophy-filled'),
  },
  {
    title: 'leaderboard',
    path: '/dashboard/leaderboard',
    icon: getIcon('iconoir:leaderboard-star'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
