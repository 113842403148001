import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  Grid,
  Box,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Draw({ closeDrawModal, closeDrawModalSuccess }) {
  const { id } = useParams();
  const [winnerModal, setWinnerModal] = useState(false);

  const winnerSchema = Yup.object().shape({
    position: Yup.string().required('Position is required'),
  });

  const formik = useFormik({
    initialValues: {
      position: '',
      matchId: id,
    },
    validationSchema: winnerSchema,
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/draw/admin`, { ...values, matchId: id });
        if (!res.data.isError) {
          toast.success(res.data.message);
          closeDrawModalSuccess();
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const modal = true;

  return (
    <div>
      {' '}
      {/* Add winner Dialog */}
      <Dialog open={modal}>
        <DialogTitle>Add Winner</DialogTitle>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} mt={1} sx={{ minWidth: '300px' }}>
                <Grid item xs={12}>
                  <TextField
                    select
                    InputLabelProps={{ shrink: true }}
                    label="Position"
                    fullWidth
                    {...getFieldProps('position')}
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={closeDrawModal}>Close</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Add Winner
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
      {/* Add winner dialog end */}
    </div>
  );
}
